/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState } from 'react'
import { css, jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import useBlog from '@polyblog/react/useBlog.js'
import { useContext } from '@monorepo/context/index.js'
import languages from '@stiloso/shared/languages.js'
import LanguageDialog from '../dialogs/LanguageDialog.js'

const navigation = css`
  align-items: center;
  color: white;
  display: flex;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  padding: 8px 16px;
  position: absolute;
  right: 0;
  top: 0;
  transition: 'all .5s ease-in-out';
  width: 100%;
  z-index: 1000;
`

const links = css`
  display: flex;
  flex-grow: 1;
  list-style-type: none;
  font-size: 20px;
  line-height: 26px;
  margin: 0px;
  padding: 0px;
`

const homeLink = css`
  cursor: pointer;
  font-size: 20px;
  font-weight: 800;
  margin-right: 16px;
  overflow: hidden;
  padding: 10px 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  :hover {
    opacity: 0.8;
  }
`

const link = css`
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  display: block;
  font-size: 12px;
  font-weight: 800;
  height: 46px;
  letter-spacing: 1px;
  overflow: hidden;
  padding: 10px 20px;
  text-transform: uppercase;
  white-space: nowrap;
  :hover {
    opacity: 0.8;
  }
`

const NavigationDesktopComponent = ({ locale }) => {
  const { t } = useTranslation(locale)
  let [context] = useContext()

  const blogName = context.subdomain

  let { data: blog } = useBlog(blogName)

  const currentLanguageName = languages[locale]

  const [languageDialog, setLanguageDialog] = useState()

  const toggleLanguageDialog = () => {
    setLanguageDialog(!languageDialog)
  }

  const blogHome = t('__blog_home__')

  return (
    <nav css={navigation}>
      <div
        css={{
          width: '100%',
          display: 'flex',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <a css={homeLink} href={`/blog/${locale}/`}>
          {t('__blog_title__', blogName)}
        </a>
      </div>
      <ul css={links}>
        {blogHome && (
          <li>
            <a css={link} href={`/${locale}/`}>
              {blogHome}
            </a>
          </li>
        )}
        {blog?.categories?.map(category => (
          <li key={category}>
            <a
              css={link}
              href={`/blog/${locale}/categories/${t(
                `__category_${category}_slug__`,
                category,
              )}`}
            >
              {t(`__category_${category}_label__`, category)}
            </a>
          </li>
        ))}
        {blog?.locales?.length > 1 && (
          <li>
            <span
              onClick={toggleLanguageDialog}
              css={[link, { marginRight: 0, cursor: 'pointer' }]}
            >
              {currentLanguageName} ▼
            </span>
          </li>
        )}
      </ul>

      {languageDialog && (
        <LanguageDialog currentLocale={locale} close={toggleLanguageDialog} />
      )}
    </nav>
  )
}

export default NavigationDesktopComponent
