/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import useBlog from '@polyblog/react/useBlog.js'
import { useContext } from '@monorepo/context/index.js'

const footer = css({
  width: '100%',
  backgroundColor: 'white',
})

const footerText = css({
  p: {
    marginTop: -4,
  },
})

const footerHorizontalBar = css({
  height: 1,
  opacity: 0.2,
  margin: '0px 60px 8px 60px',
})

const FooterComponent = ({ locale }) => {
  const { t } = useTranslation(locale)

  let [context] = useContext()

  const blogName = context.subdomain
  let { data: blog } = useBlog(blogName)

  return (
    <footer css={footer}>
      <hr css={footerHorizontalBar} />
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          padding: '32px 0px',
          marginTop: -5,
        }}
      >
        <span css={footerText}>
          {t('Copyright')}{' '}
          <a
            href={`/${locale}/`}
            css={{
              color: 'var(--color-primary)',
              ':hover': {
                color: 'var(--color-primary-light)',
              },
            }}
          >
            {t('__blog_home__', blog?.name)}
          </a>{' '}
          {new Date().getFullYear()}. Built with{' '}
          <a
            href={`https://www.polyblog.io/${locale}/`}
            css={{ color: 'var(--color-primary)' }}
          >
            Polyblog
          </a>
        </span>
      </div>
    </footer>
  )
}

export default FooterComponent
