/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import IconDone from '@stiloso/icons/IconDone.js'
import languages from '@stiloso/shared/languages.js'
import Modal from '@stiloso/components/Modal.js'
import { useContext } from '@monorepo/context/index.js'
import useBlog from '@polyblog/react/useBlog.js'

const languageStyle = css({
  fontSize: 18,
  color: '#666',
  padding: 10,
  borderBottom: '1px solid #d3d3d3',
  fill: 'var(--color-primary)',
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'space-between',
  ':hover': {
    backgroundColor: 'var(--color-primary)',
    color: 'white',
    cursor: 'pointer',
    fill: 'white',
  },
})

const LanguageDialog = ({ currentLocale, close }) => {
  let [context] = useContext()
  const blogName = context.subdomain
  const { data: blog } = useBlog(blogName)
  const { locales = [] } = blog

  return (
    <Modal close={close}>
      <div
        css={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          maxHeight: '80%',
        }}
      >
        <div css={languageStyle}>
          <span>{languages[currentLocale]}</span>
          <IconDone />
        </div>
        {locales.map(
          locale =>
            locale !== currentLocale && (
              <a href={`/blog/${locale}/`} key={locale} css={languageStyle}>
                {languages[locale]}
              </a>
            ),
        )}
      </div>
    </Modal>
  )
}

export default LanguageDialog
