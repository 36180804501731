/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState } from 'react'
import { css, jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import useBlog from '@polyblog/react/useBlog.js'
import { useContext } from '@monorepo/context/index.js'
import languages from '@stiloso/shared/languages.js'
import LanguageDialog from '../dialogs/LanguageDialog.js'

const navigation = css({
  color: 'white',
  backgroundColor: 'var(--color-primary)',
  display: 'flex',
  position: 'fixed',
  justifyContent: 'space-between',
  top: 0,
  zIndex: 1000,
  width: '100%',
  height: 64,
})

const drawerContainer = css({
  cursor: 'pointer',
  height: 64,
  padding: '17px 20px',
  position: 'relative',
  width: 64,
})

const drawerIcon = expanded =>
  css({
    label: 'drawer-icon',
    width: 24,
    position: 'absolute',
    top: '40%',

    ':after, :before': {
      content: "''",
      width: 24,
      height: 2,
      position: 'absolute',
      backgroundColor: 'white',
      transition: '.3s ease-in-out',
      top: expanded ? 5 : 0,
      transform: expanded ? 'rotate(45deg)' : 0,
    },

    ':before': {
      top: expanded ? 5 : 10,
      transform: expanded ? 'rotate(-45deg)' : 0,
    },
  })

const links = expanded =>
  css({
    overflow: 'hidden',
    height: `${expanded ? '100%' : '0px'}`,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'var(--color-primary)',
    position: 'fixed',
    top: 64,
    left: 0,
    zIndex: 1000,
    transition: 'height 0.4s ease-in-out',
  })

const link = css({
  color: 'white',
  fontSize: 16,
  position: 'relative',
  height: 45,
  cursor: 'pointer',
  borderBottom: 'solid',
  borderBottomWidth: 1,
  borderBottomColor: '#DCDCDC',
  padding: 15,
  margin: '0px 20px',
  paddingLeft: 0,
  ':hover': {
    backgroundColor: 'var(--color-primary-light)',
  },
})

const NavigationSmartphoneComponent = ({ locale = 'en' }) => {
  const { t } = useTranslation(locale)
  let [context] = useContext()

  const blogName = context.subdomain

  let { data: blog } = useBlog(blogName)

  const currentLanguageName = languages[locale]

  const title = t('__blog_title__', blogName)
  const blogHome = t('__blog_home__')

  const [expanded, setExpanded] = useState()
  const [languageDialog, setLanguageDialog] = useState()

  const toggleDrawer = () => {
    setExpanded(!expanded)
  }

  const toggleLanguageDialog = () => {
    setLanguageDialog(!languageDialog)
  }

  return (
    <nav css={navigation}>
      <div css={drawerContainer} onClick={toggleDrawer}>
        {(blog?.categories || blog?.locales?.length) && (
          <div css={drawerIcon(expanded)} data-testid="icon-drawer" />
        )}
      </div>

      <div css={links(expanded)}>
        {blogHome && (
          <a css={link} href={`/${locale}/`}>
            {blogHome}
          </a>
        )}
        {blog?.categories?.map(category => (
          <a
            key={category}
            css={link}
            href={`/blog/${locale}/categories/${t(
              `__category_${category}_slug__`,
              category,
            )}`}
          >
            {t(`__category_${category}_label__`, category)}
          </a>
        ))}
        {blog?.locales?.length > 1 && (
          <span onClick={toggleLanguageDialog} css={link}>
            {currentLanguageName}
          </span>
        )}
      </div>

      <a
        href={`/blog/${locale}/`}
        css={{
          // display: 'flex',
          // flexGrow: 1,
          fontSize: '20px',
          lineHeight: '64px',
          margin: 0,
          padding: 0,
          // textAlign: 'center',
        }}
      >
        {title}
      </a>

      <div css={{ width: 64 }} />

      {languageDialog && (
        <LanguageDialog currentLocale={locale} close={toggleLanguageDialog} />
      )}
    </nav>
  )
}

export default NavigationSmartphoneComponent
