/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import getBrowserLanguage from '@monorepo/shared/getBrowserLanguage.js'
import Layout from '../components/LayoutComponent.js'
import languages from '../languages.js'

export const paths = languages
  .map(locale => ({
    path: `/blog/${locale}/not-found/`,
    props: {
      locale,
    },
  }))
  .concat({
    path: '*',
  })

const NotFoundPage = ({ locale }) => {
  locale = locale || getBrowserLanguage()

  return (
    <Layout locale={locale}>
      <div
        css={{
          minHeight: 420,
          backgroundColor: 'var(--color-primary)',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '46px',
        }}
      >
        Page not found
      </div>
    </Layout>
  )
}

export default NotFoundPage
