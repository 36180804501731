/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { css, jsx } from '@emotion/react'
import useArticles from '@polyblog/react/useArticles.js'
import useBlog from '@polyblog/react/useBlog.js'
import { useTranslation } from '@multilocale/react/index.js'
import { useContext } from '@monorepo/context/index.js'
import colSm12 from '@stiloso/styles/bootstrap/colSm12.js'
import colMd6 from '@stiloso/styles/bootstrap/colMd6.js'
import colMd10 from '@stiloso/styles/bootstrap/colMd10.js'
import colLg4 from '@stiloso/styles/bootstrap/colLg4.js'
import colLg8 from '@stiloso/styles/bootstrap/colLg8.js'
import colXl4 from '@stiloso/styles/bootstrap/colXl4.js'
import container from '@stiloso/styles/bootstrap/container.js'
import row from '@stiloso/styles/bootstrap/row.js'
import categoriesJson from '../categories.json'
import Layout from '../components/LayoutComponent.js'
import formatDate from '../formatDate.js'
import overlay from '../styles/overlay.js'
import masthead from '../styles/masthead.js'
import languages from '../languages.js'

export const paths = languages.map(locale => ({
  path: `/blog/${locale}/categories/:category/`,
  props: {
    locale,
  },
}))

export const Head = props => {
  let { locale } = props
  const navigate = useNavigate()
  let { category: categorySlug } = useParams()
  let [context] = useContext()

  const blogName = context.subdomain

  const categories = categoriesJson[locale]
    ? categoriesJson[locale]
    : categoriesJson.en

  const strings = Object.values(categories).find(
    ({ slug }) => slug === categorySlug,
  )

  if (!strings) {
    navigate(`/blog/${locale}/not-found`)
  }

  const title = strings.name
  const description = strings.name
  const url = `/blog/${locale}/categories/${strings.slug}/`

  let { data: blog } = useBlog(blogName)

  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={blog?.name} />
      <meta property="og:image" content={blog?.coverUrl} />
      {blog?.customDomain && url && (
        <meta
          property="og:url"
          content={`https://www.${blog?.customDomain}${url}`}
        />
      )}
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={blog?.coverUrl} />
      {blog?.customDomain && url && (
        <link
          rel="canonical"
          href={`https://www.${blog?.customDomain}${url}`}
        />
      )}
      <meta name="application-name" content={blog?.name} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={blog?.coverUrl} />
    </>
  )
}

const siteHeading = css`
  color: white;
  padding: 200px 0 150px;
  text-align: center;
  @media (min-width: 768px) {
    padding: 200px 0;
  }
`

const siteTitle = css({
  display: 'block',
  lineHeight: '46px',
  fontSize: 40,
  margin: 0,
  '@media (max-width: 767px)': {
    fontSize: 34,
  },
})

const articleBox = css`
  border: 1px solid #ededed;
  display: block;
  margin-top: 15px;
  :hover * {
    color: var(--color-primary) !important;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
  }
`

const articleImage = css`
  font-size: 20px;
  line-height: 26px;
`

const articleDetail = css`
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 44px;
`

const articleTitle = css`
  color: #333;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 10px;
`

const articleDescription = css`
  color: rgb(33, 37, 41);
  font-size: 20px;
  font-family: Georgia, Cambria, 'Times New Roman', Times, serif;
  line-height: 30px;
  margin: 30px 0;
`

const articlePostedBy = css`
  border-top: 1px solid rgb(236, 236, 236);
  color: #333;
  font-family: Georgia, Cambria, 'Times New Roman', Times, serif;
  font-size: 16px;
  line-height: 26px;
  padding-top: 17px;
  padding-bottom: 5px;
  padding-left: 3px;
`

const errorStyle = css({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 160,
  fontSize: 36,
  color: 'red',
})

const CategoryPage = props => {
  let { category: categorySlug, locale } = props
  const navigate = useNavigate()

  const { t } = useTranslation(locale)
  let [context] = useContext()

  const blogName = context.subdomain

  const categories = categoriesJson[locale]
    ? categoriesJson[locale]
    : categoriesJson.en

  const strings = Object.values(categories).find(
    ({ slug }) => slug === categorySlug,
  )

  if (!strings) {
    navigate(`/blog/${locale}/not-found`)
  }

  const title = strings.name

  let { data: blog, error: errorBlog, loading: loadingBlog } = useBlog(blogName)

  let {
    data: articles,
    error: errorArticles,
    loading: loadingArticles,
  } = useArticles(
    {
      blogId: blog._id,
      locale,
      category: categorySlug,
      published: true,
      sortField: 'lastRewriteTime',
      sortDirection: 'DESC',
    },
    {
      enabled: !!blog,
    },
  )

  if (articles) {
    articles = articles.map(article => {
      let postedBy = t('Posted by {{author}} on {{date}}')
      postedBy = postedBy
        .replace('{{author}}', article.author || 'Joe Dodds')
        .replace(
          '{{date}}',
          formatDate({
            date: article.lastRewriteTime || article.creationTime,
            locale: article.locale,
          }),
        )

      return {
        ...article,
        url: `/blog/${article.locale}/${article.slug}/`,
        postedBy,
      }
    })
  }

  let error = errorBlog || errorArticles
  const loading = loadingBlog || loadingArticles

  if (!articles.length) {
    error = t('No article found')
  }

  return (
    <Layout locale={locale}>
      {loading && <div className="loader" />}
      <header css={masthead}>
        <picture>
          <source
            type="image/webp"
            media="(min-width: 1920px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 960px)"
            srcSet={blog?.coverUrl?.replace('.jpg', '-1920w.webp')}
          />
          <source
            type="image/webp"
            media="(min-width: 1600px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 800px)"
            srcSet={blog?.coverUrl?.replace('.jpg', '-1600w.webp')}
          />
          <source
            type="image/webp"
            media="(min-width: 1366px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 683px)"
            srcSet={blog?.coverUrl?.replace('.jpg', '-1366w.webp')}
          />
          <source
            type="image/webp"
            media="(min-width: 1024px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 512px)"
            srcSet={blog?.coverUrl?.replace('.jpg', '-1024w.webp')}
          />
          <source
            type="image/webp"
            media="(min-width: 768px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 384px)"
            srcSet={blog?.coverUrl?.replace('.jpg', '-768w.webp')}
          />
          <source
            type="image/webp"
            srcSet={blog?.coverUrl?.replace('.jpg', '-640w.webp')}
          />

          <source
            type="image/jpeg"
            media="(min-width: 1920px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 960px)"
            srcSet={blog?.coverUrl?.replace('.jpg', '-1920w.jpg')}
          />
          <source
            type="image/jpeg"
            media="(min-width: 1600px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 800px)"
            srcSet={blog?.coverUrl?.replace('.jpg', '-1600w.jpg')}
          />
          <source
            type="image/jpeg"
            media="(min-width: 1366px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 683px)"
            srcSet={blog?.coverUrl?.replace('.jpg', '-1366w.jpg')}
          />
          <source
            type="image/jpeg"
            media="(min-width: 1024px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 512px)"
            srcSet={blog?.coverUrl?.replace('.jpg', '-1024w.jpg')}
          />
          <source
            type="image/jpeg"
            media="(min-width: 768px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 384px)"
            srcSet={blog?.coverUrl?.replace('.jpg', '-768w.jpg')}
          />
          <source
            type="image/jpeg"
            srcSet={blog?.coverUrl?.replace('.jpg', '-640w.jpg')}
          />

          <img
            css={{
              height: '100%',
              position: 'absolute',
              top: 0,
              padding: 0,
              objectFit: 'cover',
            }}
            src={blog?.coverUrl?.replace('.jpg', '-1024w.jpg')}
            alt={title}
          />
        </picture>
        <div css={overlay} />
        <div css={container}>
          <div css={row}>
            <div
              css={[
                colMd10,
                colLg8,
                { marginLeft: 'auto', marginRight: 'auto' },
              ]}
            >
              <div css={siteHeading}>
                <h1 css={siteTitle}>{title}</h1>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section
        css={[
          container,
          { padding: '0 15px', display: 'flex', flexWrap: 'wrap' },
        ]}
      >
        {articles.map(
          ({ _id, coverUrl, title, description, url, postedBy }) => (
            <div key={_id} css={[colSm12, colMd6, colLg4, colXl4]}>
              <a href={url} css={articleBox}>
                <div css={articleImage}>
                  <picture>
                    <source
                      type="image/webp"
                      media="(-webkit-min-device-pixel-ratio: 2)"
                      srcSet={coverUrl?.replace('.jpg', '-768w.webp')}
                    />
                    <source
                      type="image/webp"
                      srcSet={coverUrl?.replace('.jpg', '-640w.webp')}
                    />

                    <source
                      type="image/jpeg"
                      media="(-webkit-min-device-pixel-ratio: 2)"
                      srcSet={coverUrl?.replace('.jpg', '-768w.jpg')}
                    />
                    <source
                      type="image/jpeg"
                      srcSet={coverUrl?.replace('.jpg', '-640w.jpg')}
                    />

                    <img
                      className="img-fluid center-block"
                      src={coverUrl?.replace('.jpg', '-640w.jpg')}
                      alt={title}
                    />
                  </picture>
                </div>
                <div css={articleDetail}>
                  <div css={articleTitle}>{title}</div>
                  <div css={articleDescription}>{description}</div>
                  <div css={articlePostedBy}>{postedBy}</div>
                </div>
              </a>
            </div>
          ),
        )}

        {error && <div css={errorStyle}>{error}</div>}
      </section>
    </Layout>
  )
}

export default CategoryPage
