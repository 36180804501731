/* Copyright 2013 - 2024 Waiterio LLC */
export default function getSubdomain(host = '') {
  let subdomain

  if (!host && typeof window !== 'undefined') {
    host = window?.location?.host || ''
  }

  host = host.replace('www.', '')
  host = host.replace('-staging', '')

  let [firstPart] = host.split('.')

  if (firstPart.indexOf('localhost') === -1) {
    subdomain = firstPart
  }

  return subdomain
}
