/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { createSelector } from 'reselect'
import { css, jsx } from '@emotion/react'
import ReactMarkdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import prism from 'react-syntax-highlighter/dist/esm/styles/prism/prism.js'
import addContact from '@polyblog/client/addContact.js'
import getArticles from '@polyblog/client/getArticles.js'
import useArticles from '@polyblog/react/useArticles.js'
import useBlog from '@polyblog/react/useBlog.js'
import { useTranslation } from '@multilocale/react/index.js'
import IconFacebook from '@stiloso/icons/IconFacebook.js'
import IconLinkedin from '@stiloso/icons/IconLinkedin.js'
import IconTwitter from '@stiloso/icons/IconTwitter.js'
import { useContext } from '@monorepo/context/index.js'
import uuid from '@monorepo/shared/uuid.js'
import colMd6 from '@stiloso/styles/bootstrap/colMd6.js'
import colMd10 from '@stiloso/styles/bootstrap/colMd10.js'
import colMd12 from '@stiloso/styles/bootstrap/colMd12.js'
import colLg3 from '@stiloso/styles/bootstrap/colLg3.js'
import colLg4 from '@stiloso/styles/bootstrap/colLg4.js'
import colLg8 from '@stiloso/styles/bootstrap/colLg8.js'
import dLgBlock from '@stiloso/styles/bootstrap/dLgBlock.js'
import dNone from '@stiloso/styles/bootstrap/dNone.js'
import colSm12 from '@stiloso/styles/bootstrap/colSm12.js'
import container from '@stiloso/styles/bootstrap/container.js'
import row from '@stiloso/styles/bootstrap/row.js'
import Button from '@stiloso/components/Button.js'
import Layout from '../components/LayoutComponent.js'
import formatDate from '../formatDate.js'
import authors from '../authors.json'
import masthead from '../styles/masthead.js'
import overlay from '../styles/overlay.js'
import languages from '../languages.js'

const recentArticles = context => context.data.recentArticles

const articleProp = (context, props) => props.article
const authorsProp = (context, props) => props.authors
const blogNameProp = (context, props) => props.blogName
const tProp = (context, props) => props.t

const authorSelector = createSelector(
  [authorsProp, articleProp],
  (authors, article) => {
    if (article?.locale === 'en') {
      let author = authors.find(author => author.fullName === article.author)

      return author
    }
  },
)

export const recentArticlesSelector = createSelector(
  [recentArticles, tProp, blogNameProp],
  (articles, t, blogName) => {
    if (articles) {
      articles = Object.values(articles).map(article => {
        let postedBy =
          t?.('Posted by {{author}} on {{date}}') ||
          'Posted by {{author}} on {{date}}'
        postedBy = postedBy
          .replace('{{author}}', article?.author || blogName)
          .replace(
            '{{date}}',
            formatDate({
              date: article?.creationTime,
              locale: article?.locale,
            }),
          )

        return {
          ...article,
          url: `/blog/${article.locale}/${article.slug}/`,
          postedBy,
        }
      })
    }

    return articles
  },
)

export const paths = languages.map(locale => ({
  path: `/blog/${locale}/:slug/`,
  props: {
    locale,
  },
}))

export const Head = ({ locale }) => {
  const { slug } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation(locale)
  let [context] = useContext()

  const blogName = context.subdomain

  let { data: blog } = useBlog(blogName)

  const { data: articles } = useArticles({
    blogId: blog._id,
    slug,
    locale,
  })

  if (articles?.length === 0) {
    navigate(`/blog/${locale}/not-found`)
  }

  let article = articles?.[0]
  const author = authorSelector(context, { article, authors, t })

  const title = `${article?.title}`
  const description = article?.description

  let url = article ? `/blog/${locale}/${article?.slug}/` : ''

  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="robots" content="max-image-preview:large" />
      <meta name="twitter:title" content={`${article?.title}`} />
      <meta name="twitter:description" content={article?.description} />
      <meta name="twitter:image" content={article?.coverUrl} />
      <meta name="article:author" content={article?.author} />
      <meta property="og:site_name" content={blog?.name} />
      <meta property="og:title" content={`${article?.title}`} />
      <meta property="og:description" content={article?.description} />
      <meta property="og:type" content="article" />
      <meta
        property="og:url"
        content={`https://www.${blog?.customDomain}${url}`}
      />
      <meta property="og:image" content={article?.coverUrl} />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={article?.description} />
      <meta itemProp="image" content={article?.coverUrl} />
      {blog?.customDomain && url && (
        <link
          rel="canonical"
          href={`https://www.${blog?.customDomain}${url}`}
        />
      )}
      {blog?.customDomain && (
        <link
          rel="alternate"
          type="application/rss+xml"
          href={`https://www.${blog?.customDomain}/blog/${locale}/feed/`}
        />
      )}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org/',
            '@type': 'BlogPosting',
            mainEntityOfPage: {
              '@type': 'WebPage',
              '@id': url && `https://www.${blog?.customDomain}${url}`,
            },
            headline: article?.description,
            description,
            image: article?.coverUrl,
            author: {
              '@type': 'Person',
              name: author?.fullName,
            },
            publisher: {
              '@type': 'Organization',
              name: blog?.name,
              logo: {
                '@type': 'ImageObject',
                url: 'https://assets.waiterio.com/icons/favicon-196x196.png',
              },
            },
            datePublished:
              article && new Date(article.lastRewriteTime).toLocaleDateString(),
            dateModified:
              article && new Date(article.lastEditTime).toLocaleDateString(),
          }),
        }}
      />
    </>
  )
}

const postHeading = css`
  color: white;
  margin-bottom: -130px;
  padding: 200px 0 150px;
  @media (min-width: 768px) {
    padding: 200px 0;
  }
`

const articleTitle = css`
  font-size: 35px;
  font-weight: 800;
  line-height: 58px;
  margin-bottom: 0px;
  margin-top: 0px;
  @media (min-width: 768px) {
    font-size: 55px;
  }
`

const articleDescription = css`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 30px;
  margin-top: 10px;
  @media (min-width: 768px) {
    font-size: 30px;
  }
`

const articlePostedBy = css`
  font-family: Georgia, Cambria, 'Times New Roman', Times, serif;
  font-size: 20px;
  font-style: italic;
  font-weight: 300;
  line-height: 22px;
`

const sectionArticle = css`
  ${container}
  color: rgb(33, 37, 41);
  display: block;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 15px;
  margin-top: 15px;
`

const containerFluid = css`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`

const articleBox = css`
  padding: 20px 15px 15px 15px;
  max-width: 55ch;
`

const articleContent = css`
  color: rgb(33, 37, 41);
  font-family: Georgia, Cambria, 'Times New Roman', Times, serif;
  font-size: 20px;
  line-height: 35px;
  & a {
    color: var(--color-primary);
    :hover {
      color: #5cb85c;
    }
  }
  & img {
    padding-bottom: 24px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 512px;
  }
  & p {
    margin: 30px 0;
  }
  & h2 {
    font-size: 35px;
    font-weight: 800;
    line-height: 46px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    @media (max-width: 767px) {
      font-size: 34px;
    }
  }
  & h3 {
    font-size: 30px;
    font-weight: 500;
    line-height: 40px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    @media (max-width: 767px) {
      font-size: 26px;
      line-height: 32px;
    }
  }

  & ul {
    margin: 0px;
    padding: 5px;
  }

  & ol > ul {
    margin-left: 20px;
  }

  & li {
    margin: 15px;
  }

  .iframe-video-container {
    display: flex;
    flex: 1;
    justify-content: center;
  }

  pre[class*='language-'] {
    font-size: 18px !important;
    margin-left: -40px !important;
    margin-right: -40px !important;
  }
`

const widgetTitle = css`
  color: rgb(51, 51, 51);
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  margin-block-end: 20px;
  margin-block-start: 0px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  text-transform: capitalize;
`

const recentArticleBox = css`
  background-color: white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
  display: block;
  transition: all 0.25s ease-in-out;

  @media (hover: hover) {
    :hover {
      box-shadow:
        0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      transform: translateY(-10px);
      fill: var(--color-primary);
      h5 {
        color: var(--color-secondary-light);
      }
    }
  }

  &:not(.disabled):active {
    h5 {
      color: var(--color-secondary-dark);
    }
  }
`

const recentArticleTitle = css`
  color: #666;
  cursor: pointer;
  font-family: Georgia, Cambria, 'Times New Roman', Times, serif;
  font-size: 16px;
  font-weight: 600;
`

const recentArticleDescription = css`
  font-family: Georgia, Cambria, 'Times New Roman', Times, serif;
  font-size: 16px;
  margin-top: 15px;
`

const recentArticlePostedBy = css`
  color: #666;
  font-family: Georgia, Cambria, 'Times New Roman', Times, serif;
  font-size: 15px;
  padding-inline-start: 2px;
  margin-top: 15px;
  overflow: hidden;
  white-space: nowrap;
`

const sectionNewsletter = css`
  background-color: var(--color-primary);
  padding: 60px 0px;
`

const errorStyle = css({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 160,
  fontSize: 36,
  color: 'red',
})

const RecentArticlesSection = ({ blogId, locale }) => {
  const { t } = useTranslation(locale)
  let [context, produce] = useContext()

  const blogName = context.subdomain

  let recentArticles = recentArticlesSelector(context, { blogName, locale, t })

  useEffect(() => {
    let cancelled = false

    const fetchRecentArticles = async () => {
      try {
        if (!recentArticles) {
          let recentArticles = await getArticles({
            blogId,
            locale,
            limit: 3,
            published: true,
            sortField: 'lastRewriteTime',
            sortDirection: 'DESC',
          })

          if (!cancelled) {
            produce(draft => {
              draft.data.recentArticles = recentArticles
            })
          }
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchRecentArticles()

    return () => {
      cancelled = true
    }
  }, [produce, recentArticles, blogId, locale])

  return (
    <section
      css={[
        {
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          backgroundColor: '#f5f5f5',
          paddingTop: 40,
          paddingBottom: 40,
          '@media(max-width:767px)': {
            paddingTop: 40,
            paddingBottom: 40,
          },
        },
      ]}
    >
      <div css={container}>
        <div css={[row]}>
          <h4 css={[widgetTitle, colMd12]}>{t('Recent articles')}</h4>
        </div>
        <div css={row}>
          {recentArticles?.map(
            ({ _id, postedBy, coverUrl, title, url, description }) => (
              <div
                key={_id}
                css={[
                  colSm12,
                  colMd6,
                  colLg4,
                  {
                    marginBottom: 30,
                  },
                ]}
              >
                <a href={url} css={recentArticleBox}>
                  <div css={{ aspectRatio: '1920 / 1280' }}>
                    <picture>
                      <source
                        type="image/webp"
                        media="(-webkit-min-device-pixel-ratio: 2)"
                        srcSet={coverUrl?.replace('.jpg', '-768w.webp')}
                      />
                      <source
                        type="image/webp"
                        srcSet={coverUrl?.replace('.jpg', '-640w.webp')}
                      />
                      <source
                        type="image/jpeg"
                        media="(-webkit-min-device-pixel-ratio: 2)"
                        srcSet={coverUrl?.replace('.jpg', '-768w.jpg')}
                      />
                      <source
                        type="image/jpeg"
                        srcSet={coverUrl?.replace('.jpg', '-640w.jpg')}
                      />
                      <img
                        src={coverUrl?.replace('.jpg', '-640w.jpg')}
                        alt={title}
                      />
                    </picture>
                  </div>
                  <div
                    css={{
                      backgroundColor: 'white',
                      padding: 14,
                      marginTop: -2,
                    }}
                  >
                    <h5 css={recentArticleTitle}>{title}</h5>
                    <div css={recentArticleDescription}>{description}</div>
                    <div css={recentArticlePostedBy}>{postedBy}</div>
                  </div>
                </a>
              </div>
            ),
          )}
        </div>
      </div>
    </section>
  )
}

const ArticlePage = ({ locale }) => {
  const { slug } = useParams()
  const { t } = useTranslation(locale)
  const navigate = useNavigate()
  let [context] = useContext()
  let [email, setEmail] = useState()
  let [emailSignupSuccessMessage, setEmailSignupSuccessMessage] = useState()

  const blogName = context.subdomain

  let { data: blog, error: errorBlog } = useBlog(blogName)

  const {
    data: articles,
    isLoading,
    error: errorArticles,
  } = useArticles(
    {
      blogId: blog._id,
      slug,
      locale,
    },
    {
      enabled: !!blog,
    },
  )

  let error

  if (errorBlog) {
    error = errorArticles && errorArticles.message
  }

  if (errorArticles) {
    error = errorArticles && errorArticles.message
  }

  if (articles?.length === 0) {
    navigate(`/blog/${locale}/not-found`)
  }

  let article = articles?.[0]

  let postedBy =
    t?.('Posted by {{author}} on {{date}}') ||
    'Posted by {{author}} on {{date}}'

  postedBy = postedBy
    .replace('{{author}}', article?.author || blogName)
    .replace(
      '{{date}}',
      formatDate({
        date: article?.lastRewriteTime || article?.creationTime,
        locale: article?.locale,
      }),
    )

  let url = article ? `/blog/${locale}/${article?.slug}/` : ''

  const onShareBlog = (href, shareType) => {
    window.open(href, shareType, 'width=780,height=500')
  }

  const signupToNewsletter = event => {
    event.preventDefault()

    const creationTime = new Date().toISOString()

    addContact({
      _id: uuid(),
      creationTime,
      lastEditTime: creationTime,
      blogId: blog._id,
      email,
    })

    setEmailSignupSuccessMessage(
      t("Thanks! You're now subscribed to our newsletter"),
    )
  }

  const author = authorSelector(context, { article, authors, t })
  const ctaDescription = t('__cta_description__', t('__blog_home__'))

  return (
    <Layout locale={locale}>
      {isLoading && <div className="loader" />}

      {error && <div css={errorStyle}>{error}</div>}

      {article && (
        <>
          <link
            href="https://cdnjs.cloudflare.com/ajax/libs/prism/1.26.0/themes/prism.css"
            rel="stylesheet"
          />
          <script src="https://cdnjs.cloudflare.com/ajax/libs/prism/1.26.0/prism.min.js" />
          <script src="https://cdnjs.cloudflare.com/ajax/libs/prism/1.26.0/plugins/autoloader/prism-autoloader.min.js" />
          <header css={masthead}>
            <picture>
              <source
                type="image/webp"
                media="(min-width: 1920px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 960px)"
                srcSet={article.coverUrl?.replace('.jpg', '-1920w.webp')}
              />
              <source
                type="image/webp"
                media="(min-width: 1600px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 800px)"
                srcSet={article.coverUrl?.replace('.jpg', '-1600w.webp')}
              />
              <source
                type="image/webp"
                media="(min-width: 1366px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 683px)"
                srcSet={article.coverUrl?.replace('.jpg', '-1366w.webp')}
              />
              <source
                type="image/webp"
                media="(min-width: 1024px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 512px)"
                srcSet={article.coverUrl?.replace('.jpg', '-1024w.webp')}
              />
              <source
                type="image/webp"
                media="(min-width: 768px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 384px)"
                srcSet={article.coverUrl?.replace('.jpg', '-768w.webp')}
              />
              <source
                type="image/webp"
                srcSet={article.coverUrl?.replace('.jpg', '-640w.webp')}
              />

              <source
                type="image/jpeg"
                media="(min-width: 1920px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 960px)"
                srcSet={article.coverUrl?.replace('.jpg', '-1920w.jpg')}
              />
              <source
                type="image/jpeg"
                media="(min-width: 1600px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 800px)"
                srcSet={article.coverUrl?.replace('.jpg', '-1600w.jpg')}
              />
              <source
                type="image/jpeg"
                media="(min-width: 1366px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 683px)"
                srcSet={article.coverUrl?.replace('.jpg', '-1366w.jpg')}
              />
              <source
                type="image/jpeg"
                media="(min-width: 1024px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 512px)"
                srcSet={article.coverUrl?.replace('.jpg', '-1024w.jpg')}
              />
              <source
                type="image/jpeg"
                media="(min-width: 768px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 384px)"
                srcSet={article.coverUrl?.replace('.jpg', '-768w.jpg')}
              />
              <source
                type="image/jpeg"
                srcSet={article.coverUrl?.replace('.jpg', '-640w.jpg')}
              />

              <img
                css={{
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  padding: 0,
                  objectFit: 'cover',
                }}
                src={article.coverUrl?.replace('.jpg', '-1024w.jpg')}
                alt={article.title}
              />
            </picture>
            <div css={overlay} className="overlay" />
            <div css={container}>
              <div css={row}>
                <div
                  css={[
                    colMd10,
                    colLg8,
                    { marginLeft: 'auto', marginRight: 'auto' },
                  ]}
                >
                  <div css={postHeading}>
                    <h1 css={articleTitle}>{article.title}</h1>
                    <h2 css={articleDescription}>{article.description}</h2>
                    <span css={articlePostedBy}>{postedBy}</span>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <section css={sectionArticle}>
            <div css={[row, { display: 'flex', marginBottom: 15 }]}>
              <div
                css={[
                  colSm12,
                  colLg8,
                  { marginLeft: 'auto', marginRight: 'auto' },
                ]}
              >
                <div css={articleBox}>
                  {article.format !== 'markdown' && (
                    <article
                      css={articleContent}
                      dangerouslySetInnerHTML={{ __html: article.content }}
                    />
                  )}
                  {article.format === 'markdown' && (
                    <article css={articleContent} translate="no">
                      <ReactMarkdown
                        components={{
                          a({ children, href, ...props }) {
                            delete props.node
                            let target = '_blank'

                            let linkDomain = href?.split('//')[1] ?? ''
                            linkDomain = linkDomain?.split('/')[0] ?? ''

                            let locationDomain = ''

                            if (typeof window !== 'undefined') {
                              locationDomain = window?.location?.href
                              locationDomain =
                                locationDomain?.split('//')[1] ?? ''
                              locationDomain =
                                locationDomain?.split('/')[0] ?? ''
                            }

                            if (
                              linkDomain === locationDomain ||
                              linkDomain.includes(blog.customDomain)
                            ) {
                              target = null
                            }

                            return (
                              <a href={href} target={target} {...props}>
                                {children}
                              </a>
                            )
                          },
                          code({ inline, className, children, ...props }) {
                            delete props.node
                            const match = /language-(\w+)/.exec(className || '')
                            return !inline && match ? (
                              <SyntaxHighlighter
                                {...props}
                                style={prism}
                                language={match[1]}
                                PreTag="div"
                              >
                                {String(children).replace(/\n$/, '')}
                              </SyntaxHighlighter>
                            ) : (
                              <code {...props} className={className}>
                                {children}
                              </code>
                            )
                          },
                        }}
                      >
                        {article.content}
                      </ReactMarkdown>
                    </article>
                  )}
                  <div
                    css={{
                      width: '100%',
                      marginTop: 30,
                      height: 2,
                      backgroundColor: '#f5f5f5',
                    }}
                  />
                  {author && (
                    <>
                      <a
                        href={`/blog/${locale}/authors/${author.id}`}
                        css={{
                          marginTop: 'auto',
                          marginBottom: 'auto',
                          marginLeft: 15,
                          color: 'var(--color-primary)',
                          ':hover': {
                            color: 'var(--color-primary-light)',
                          },
                        }}
                      >
                        <div
                          css={{
                            marginTop: 30,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <picture>
                            <source
                              type="image/webp"
                              srcSet={`${author.avatarUrl.replace(
                                '.jpg',
                                '-320w.webp',
                              )}`}
                            />
                            <img
                              src={`${author.avatarUrl.replace(
                                '.jpg',
                                '-320w.jpg',
                              )}`}
                              css={{ width: 60, height: 60, borderRadius: 36 }}
                              alt={author.fullName}
                            />
                          </picture>
                          <div css={{ paddingLeft: 16 }}>{author.fullName}</div>
                        </div>
                      </a>
                      <p css={{ fontSize: 18 }}>{author.shortDescription}</p>
                      <div
                        css={{
                          marginTop: 30,
                          width: '100%',
                          height: 2,
                          backgroundColor: '#f5f5f5',
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
              {ctaDescription && (
                <div css={[colLg3, dNone, dLgBlock]}>
                  <div
                    css={{
                      float: 'right',
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'white',
                      textAlign: 'center',
                      padding: 12,
                      marginTop: 54,
                      marginBottom: 54,
                      position: 'sticky',
                      top: 54,
                      borderRadius: 4,
                      backgroundColor:
                        blog?.colorPrimary ?? 'var(--color-primary)',
                      width: '100%',
                    }}
                  >
                    <span css={{ fontSize: 18, paddingBottom: 12 }}>
                      {ctaDescription}
                    </span>
                    {blog.faviconUrl && (
                      <img
                        src={blog.faviconUrl}
                        alt={blog.name}
                        css={{
                          width: 48,
                          marginTop: 12,
                          marginBottom: 24,
                          filter:
                            'drop-shadow(2px 2px 0 white) drop-shadow(-2px 2px 0 white) drop-shadow(2px -2px 0 white) drop-shadow(-2px -2px 0 white)',
                        }}
                      />
                    )}
                    <a
                      href={`/${locale}/`}
                      css={{ marginBottom: 5, width: '100%' }}
                    >
                      <button
                        type="submit"
                        css={{
                          cursor: 'pointer',
                          paddingTop: 12,
                          paddingBottom: 12,
                          width: '100%',
                          borderRadius: 4,
                          fontSize: 16,
                          fontWeight: '500',
                          backgroundColor: 'white',
                          border: 'white',
                          outline: 'none',
                          ':hover': {
                            color: blog?.colorPrimary,
                          },
                        }}
                      >
                        {t('__cta_button__', t('Try it now'))}
                      </button>
                    </a>
                  </div>
                </div>
              )}
            </div>

            <div css={[row, { marginTop: 30, justifyContent: 'center' }]}>
              <div css={{ display: 'flex', justifyContent: 'center' }}>
                <div
                  css={{ height: 36 }}
                  onClick={() =>
                    onShareBlog(
                      `http://www.facebook.com/sharer/sharer.php?u=https://www.${blog?.customDomain}${url}`,
                      'Facebook',
                    )
                  }
                >
                  <IconFacebook
                    css={{
                      marginInlineStart: 5,
                      fill: '#666666',
                      width: 36,
                      height: 36,
                      cursor: 'pointer',
                      ':hover': { fill: '#3a5ca9' },
                    }}
                  />
                </div>
                <div
                  css={{ height: 36 }}
                  onClick={() =>
                    onShareBlog(
                      `https://www.linkedin.com/shareArticle?mini=true&url=https://www.${blog?.customDomain}${url}`,
                      'LinkedIn',
                    )
                  }
                >
                  <IconLinkedin
                    css={{
                      marginInlineStart: 5,
                      fill: '#666666',
                      width: 36,
                      height: 36,
                      cursor: 'pointer',
                      ':hover': { fill: '#006cac' },
                    }}
                  />
                </div>
                <div
                  css={{ height: 36 }}
                  onClick={() =>
                    onShareBlog(
                      `https://twitter.com/intent/tweet?text=https://www.${blog?.customDomain}${url}`,
                      'Twitter',
                    )
                  }
                  className="twitter-share-button"
                >
                  <IconTwitter
                    css={{
                      marginInlineStart: 9,
                      marginTop: 2,
                      fill: '#666666',
                      width: 28,
                      height: 28,
                      cursor: 'pointer',
                      ':hover': { fill: '#1a97f0' },
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
          <section css={sectionNewsletter}>
            <div
              css={{ display: 'flex', flex: 1, justifyContent: 'space-around' }}
            >
              <form
                onSubmit={signupToNewsletter}
                autoComplete="on"
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  color: 'white',
                  margin: 'auto 16px',
                  width: '100%',
                }}
              >
                <h3
                  css={{
                    fontSize: 32,
                    maxWidth: 450,
                    textTransform: 'capitalize',
                  }}
                >
                  {t('Sign up for our newsletter')}
                </h3>
                <div
                  css={{
                    display: 'flex',
                    width: '100%',
                    background: 'white',
                    borderRadius: '32px',
                    marginBottom: 32,
                    maxWidth: 480,
                  }}
                >
                  <input
                    id="emailInput"
                    type="email"
                    name="email"
                    autoComplete="email"
                    autoCapitalize="none"
                    required
                    autoCorrect="off"
                    placeholder={t('email')}
                    css={{
                      outline: 'none',
                      border: 0,
                      fontSize: 16,
                      padding: 12,
                      width: '100%',
                      borderRadius: '32px 0 0 32px',
                      flexGrow: 1,
                    }}
                    value={email || ''}
                    onChange={event => {
                      setEmail(event.target.value)
                    }}
                  />
                  <Button
                    type="submit"
                    css={{
                      borderRadius: 32,
                      height: 44,
                    }}
                  >
                    {t('Sign up')}
                  </Button>
                </div>
              </form>
            </div>
            {emailSignupSuccessMessage && (
              <p
                css={{
                  margin: 0,
                  color: 'white',
                  fontSize: 16,
                  textAlign: 'center',
                }}
              >
                {emailSignupSuccessMessage}
              </p>
            )}
          </section>
          <RecentArticlesSection blogId={blog._id} locale={locale} />
        </>
      )}
    </Layout>
  )
}

export default ArticlePage
