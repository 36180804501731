/* Copyright 2013 - 2024 Waiterio LLC */
import { useQuery } from '@tanstack/react-query'
import getBlog from '@polyblog/client/getBlog.js'

export default function useBlog(blogIdOrName, organizationId) {
  return useQuery({
    queryKey: ['blogs', blogIdOrName],
    queryFn: () => getBlog(blogIdOrName, organizationId),
  })
}
