/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { useParams } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { createSelector } from 'reselect'
import useBlog from '@polyblog/react/useBlog.js'
import { useContext } from '@monorepo/context/index.js'
import colMd10 from '@stiloso/styles/bootstrap/colMd10.js'
import colLg8 from '@stiloso/styles/bootstrap/colLg8.js'
import container from '@stiloso/styles/bootstrap/container.js'
import row from '@stiloso/styles/bootstrap/row.js'
import masthead from '../styles/masthead.js'
import Layout from '../components/LayoutComponent.js'
import authors from '../authors.json'
import languages from '../languages.js'

const authorIdProps = (context, props) => props.authorId
const authorsProps = (context, props) => props.authors

const authorSelector = createSelector(
  [authorsProps, authorIdProps],
  (authors, authorId) => authors.find(author => author.id === authorId),
)

export const paths = languages.map(locale => ({
  path: `/blog/${locale}/authors/:authorId/`,
  props: {
    locale,
  },
}))

export const Head = ({ locale }) => {
  const { authorId } = useParams()
  let [context] = useContext()
  const blogName = context.subdomain
  let { data: blog } = useBlog(blogName)
  const author = authorSelector(context, { authors, authorId })
  const title = author.fullName
  const { description } = author

  let url = `/blog/${locale}/authors/${authorId}/`

  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        property="og:url"
        content={`https://www.${blog?.customDomain}${url}`}
      />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      {url && (
        <link
          rel="canonical"
          href={`https://www.${blog?.customDomain}${url}`}
        />
      )}
    </>
  )
}

const siteHeading = css`
  color: white;
  padding: 200px 0 150px;
  text-align: center;
  @media (max-width: 768px) {
    padding: 150px 0 86px;
  }
`

const siteTitle = css({
  display: 'block',
  lineHeight: '46px',
  fontSize: 36,
  margin: 0,
  '@media (max-width: 767px)': {
    fontSize: 32,
  },
})

const siteDescription = css({
  display: 'block',
  lineHeight: '26px',
  fontSize: 24,
  fontWeight: 300,
  marginTop: 10,
  marginBottom: 0,
  '@media (max-width: 767px)': {
    fontSize: 20,
  },
})

const AuthorPage = ({ locale, authorId }) => {
  let [context] = useContext()
  const author = authorSelector(context, { authors, authorId })
  const { avatarUrl, description, fullName, shortDescription } = author

  return (
    <Layout locale={locale}>
      <header css={masthead}>
        <div
          css={{
            backgroundColor: 'rgb(33, 37, 41)',
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
          }}
        />
        <div css={container}>
          <div css={row}>
            <div
              css={[
                colMd10,
                colLg8,
                { marginLeft: 'auto', marginRight: 'auto' },
              ]}
            >
              <div css={siteHeading}>
                <picture>
                  <source
                    type="image/webp"
                    srcSet={avatarUrl?.replace('.jpg', '-320w.webp')}
                  />
                  <img
                    src={avatarUrl?.replace('.jpg', '-320w.jpg')}
                    css={{
                      marginBottom: 30,
                      textAlign: 'center',
                      width: 200,
                      height: 200,
                      borderRadius: 100,
                    }}
                  />
                </picture>
                <h1 css={siteTitle}>{fullName}</h1>
                <p css={siteDescription}>{shortDescription}</p>
                <article
                  css={[siteDescription]}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
            </div>
          </div>
        </div>
      </header>
    </Layout>
  )
}

export default AuthorPage
