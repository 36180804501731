/* Copyright 2013 - 2024 Waiterio LLC */
import { css } from '@emotion/react'

export default css`
  label: overlay;
  background-color: rgb(33, 37, 41);
  height: 100%;
  left: 0px;
  opacity: 0.5;
  position: absolute;
  top: 0px;
  width: 100%;
`
