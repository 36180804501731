/* Copyright 2013 - 2024 Waiterio LLC */
import { darken, lighten } from 'polished'
import colorPrimary from './colorPrimary.js'

export default `

  :root {
    --color-primary: ${colorPrimary};
    --color-primary-dark: ${darken(0.08, colorPrimary)};
    --color-primary-darker: ${darken(0.16, colorPrimary)};
    --color-primary-darkest: ${darken(0.24, colorPrimary)};
    --color-primary-light: ${lighten(0.08, colorPrimary)};
    --color-primary-lighter: ${lighten(0.16, colorPrimary)};
    --color-primary-lightest: ${lighten(0.24, colorPrimary)};
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body, html {
    height: 100%;
    width: 100%;
    margin: 0;
    outline: 0;
    color: #626262;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: #419ed6;
  }

  body, html, button {
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Fira Sans','Droid Sans','Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  img {
    width: 100%;
  }

`
