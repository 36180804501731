/* Copyright 2013 - 2024 Waiterio LLC */
import React, { Suspense } from 'react'
import { hydrateRoot } from 'react-dom/client' // eslint-disable-line import/extensions
import { BrowserRouter } from 'react-router-dom'
import { initMultilocale } from '@multilocale/react/index.js'
import PolyblogProvider from '@polyblog/react/PolyblogProvider.js'
import StageBadge from '@stiloso/components/StageBadge.js'
import { Provider } from '@monorepo/context/index.js'
import getSentryBrowser from '@monorepo/telemetry/getSentryBrowser.js'
import ErrorBoundaryGlobalWithSentry from '@stiloso/components/ErrorBoundary.js'
import ScrollToTop from '@stiloso/components/ScrollToTop.js'
import allowSubdomainsCrossOrigin from '@monorepo/shared/allowSubdomainsCrossOrigin.js'
import Spinner from '@stiloso/components/Spinner.js'
import GlobalStyles from './styles/GlobalStyles.js'
import getInitialContext from './getInitialContext.js'
import getSubdomain from './getSubdomain.js'
import Routes from './Routes.js'

allowSubdomainsCrossOrigin()
const subdomain = getSubdomain()
const initialContext = window.__PRELOADED_CONTEXT__ || getInitialContext()
initialContext.subdomain = subdomain

initMultilocale({
  organizationId: 'e395dba6a14d8af4c2d35890',
  project: `polyblog-whitelabel-${subdomain}`,
})

hydrateRoot(
  document.getElementById('root'),
  <ErrorBoundaryGlobalWithSentry>
    <BrowserRouter>
      <PolyblogProvider>
        <Provider initialContext={initialContext}>
          <Suspense fallback={<Spinner />}>
            <ScrollToTop />
            <GlobalStyles />
            <Routes />
            <StageBadge />
          </Suspense>
        </Provider>
      </PolyblogProvider>
    </BrowserRouter>
  </ErrorBoundaryGlobalWithSentry>,
)

getSentryBrowser()
