/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'
import Navigation from './NavigationComponent.js'
import Footer from './FooterComponent.js'

const LayoutComponent = ({ locale, headerStyle, children }) => (
  <>
    <Navigation style={headerStyle} locale={locale} />
    {children}
    <Footer locale={locale} />
  </>
)

export default LayoutComponent
