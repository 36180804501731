/* Copyright 2013 - 2024 Waiterio LLC */
import { css } from '@emotion/react'

export default css`
  label: masthead;
  padding: 20px 0;
  position: relative;
  @media (max-width: 992px) {
    padding: 10px 0;
  }
`
