/* Copyright 2013 - 2024 Waiterio LLC */
export default function getInitialContext() {
  return {
    data: {
      articles: {},
    },
    language: 'en',
    pages: {
      article: {},
      articles: {},
      category: {},
    },
  }
}
